.tile {
    padding: 12px;
    width: 100%;
}

* {
 line-height: 1.5em;
}

/* .tile:nth-child(2n+1) {
    background-color: honeydew;
} */


